import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../css/MapComponent.css';
import L from 'leaflet';
import limpopoBasin from '../assets/limpopo_basin.json'; // Importing the Limpopo Basin GeoJSON file
import limpopoRiver from '../assets/limpopo_river.json'; // Importing the Limpopo River GeoJSON file
import dwsLimpopoBasinRivers from '../assets/dws_limpopo_basin_rivers.json'; // Importing the DWS Limpopo Basin Rivers GeoJSON file
import metadata from '../assets/metadata.json'; // Importing the metadata GeoJSON file

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const legend = [
  { label: 'Rainfall Sites', color: '#0000ff' },
  { label: 'Eflow Sites (RiverCode)', color: '#ff0000' }
];

const LegendControl = () => {
  const map = useMap();

  useEffect(() => {
    const legendControl = L.control({ position: 'bottomright' });

    legendControl.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      div.innerHTML = '<h4>Legend</h4>';
      legend.forEach(item => {
        div.innerHTML += `<i style="background: ${item.color}"></i> ${item.label}<br>`;
      });
      return div;
    };

    legendControl.addTo(map);

    return () => {
      legendControl.remove();
    };
  }, [map]);

  return null;
};

const RainfallMapExport = ({ minLongitude, maxLongitude, minLatitude, maxLatitude }) => {
  const [geoData, setGeoData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const geoJsonLayer = useRef(null);

  useEffect(() => {
    const fetchRainfallData = async () => {
      if (!minLongitude || !maxLongitude || !minLatitude || !maxLatitude) {
        return;
      }

      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chatbot/rainfall_site_filter?min_longitude=${minLongitude}&max_longitude=${maxLongitude}&min_latitude=${minLatitude}&max_latitude=${maxLatitude}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setGeoData(result);
      } catch (error) {
        console.error('Error fetching rainfall data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRainfallData();
  }, [minLongitude, maxLongitude, minLatitude, maxLatitude]);

  useEffect(() => {
    if (geoJsonLayer.current && geoData) {
      geoJsonLayer.current.clearLayers().addData(geoData);
      adjustLabels();
    }
  }, [geoData]);

  const adjustLabels = () => {
    const labelElements = document.querySelectorAll('.leaflet-tooltip.station-label');
    const positions = [];

    labelElements.forEach((label) => {
      const rect = label.getBoundingClientRect();

      const overlappingLabel = positions.find((pos) => {
        return (
          rect.left < pos.right &&
          rect.right > pos.left &&
          rect.top < pos.bottom &&
          rect.bottom > pos.top
        );
      });

      if (overlappingLabel) {
        const newTop = overlappingLabel.bottom + 5;
        label.style.top = `${newTop}px`;
      }

      positions.push({
        left: rect.left,
        right: rect.right,
        top: label.offsetTop,
        bottom: label.offsetTop + rect.height,
      });
    });
  };

  const pointToLayer = (feature, latlng) => {
    // Determine the color based on the feature type or properties
    let fillColor, strokeColor, label;
    
    if (feature.properties.RiverCode) {
      fillColor = "#ff0000"; // Red for RiverCode sites
      strokeColor = "#ff0000";
      label = feature.properties.RiverCode;
    } else {
      fillColor = "#0000ff"; // Blue for rainfall sites
      strokeColor = "#0000ff";
      label = feature.properties.station || "Rainfall Site";
    }
  
    const markerIcon = new L.Icon({
      iconUrl: `data:image/svg+xml;base64,${btoa(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12px" height="12px">
          <circle cx="12" cy="12" r="10" fill="${fillColor}" stroke="${strokeColor}" stroke-width="2" />
        </svg>
      `)}`,
      iconSize: [24, 24],
      iconAnchor: [12, 24],
      popupAnchor: [0, -24],
    });
  
    const marker = L.marker(latlng, { icon: markerIcon });
    marker.bindTooltip(label, { permanent: true, direction: 'left', className: 'station-label' });
    return marker;
  };
  
  const MapBoundsSetter = () => {
    const map = useMap();

    useEffect(() => {
      if (minLongitude && maxLongitude && minLatitude && maxLatitude) {
        const bounds = [
          [minLatitude, minLongitude],
          [maxLatitude, maxLongitude],
        ];
        map.fitBounds(bounds); // Set the map to fit the bounding box
      }
    }, [map]);

    return null;
  };

  return (
    <div className="RainfallMap">
      {loading && <div className="loading-spinner"></div>}
      {!loading && geoData && (
        <MapContainer
          className="MapComponent"
          zoomSnap={0.25}
          zoomDelta={0.25}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/">Humanitarian OpenStreetMap Team</a>'
          />
          {error && <p className="error-message">Error: {error.message}</p>}
          <GeoJSON key={geoData} ref={geoJsonLayer} data={geoData} pointToLayer={pointToLayer} />
          <GeoJSON
            data={limpopoBasin}
            style={{ color: '#808080', weight: 2, fillColor: '#D3D3D3', fillOpacity: 0.5 }}
          />
          <GeoJSON
            data={dwsLimpopoBasinRivers}
            style={{ color: '#ADD8E6', weight: 1.5 }}
          />
          <GeoJSON
            data={limpopoRiver}
            style={{ color: '#00008B', weight: 3 }}
          />
          <GeoJSON
            data={metadata}
            pointToLayer={pointToLayer} // Add red markers with RiverCode labels
          />
          <MapBoundsSetter />
          <LegendControl /> {/* Add the LegendControl component */}
        </MapContainer>
      )}
    </div>
  );
};

export default RainfallMapExport;
