import React, { useState, useEffect } from 'react';
import { getToken } from './auth';
import '../css/RealtimeStationsComponent.css';

const RainfallStationsComponent = ({ onStationSelect }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedStation, setSelectedStation] = useState('');

    const fetchData = async () => {
        try {
            const token = await getToken();

            const response = await fetch(`${process.env.REACT_APP_API_URL}/rainfall_stations`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleStationChange = (e) => {
        const selectedId = e.target.value;
        setSelectedStation(selectedId);
        const selectedStationData = data.find(station => station.id.toString() === selectedId);
        if (selectedStationData) {
            onStationSelect(selectedStationData);
        }
    };

    return (
        <div className="station-select-container">
            {error && <p className="error-message">Error: {error.message}</p>}
            {data.length > 0 ? (
                <select onChange={handleStationChange} value={selectedStation} className="station-select">
                    <option value="" disabled>Select a station</option>
                    {data.map(station => (
                        <option key={station.id} value={station.id.toString()}>
                            {station.channel_id}
                        </option>
                    ))}
                </select>
            ) : (
                <p className="loading-message">Loading...</p>
            )}
        </div>
    );
};

export default RainfallStationsComponent;
