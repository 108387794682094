import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';
import Legend from './LegendComponent'; 
import '../css/RainfallCirclePlot.css'; 
const monthOrder = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, label = 0, month, year } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333333">
        {year}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#000">{`${month}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${label.toFixed(1)})`}
      </text>
    </g>
  );
};

const RainfallCirclePlot = ({ dataType, metric, channelId, startDate, endDate }) => {
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/rainfall_data?data_type=${dataType}&metric=${metric}&channel_id=${channelId}&start_date=${startDate}&end_date=${endDate}`;
      try {
        const response = await fetch(url);
        const result = await response.json();

        // Fill missing months with zero values
        const filledData = result.map((yearData) => {
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const monthData = months.map(month => {
            const found = yearData.data.find(d => d.month === month);
            return found ? found : { month, value: 0, color: '#FFFFFF' }; // Default color if missing
          });
          return { year: yearData.year, data: monthData };
        });

        setData(filledData);
      } catch (error) {
        console.error('Error fetching the data', error);
      }
    };

    fetchData();
  }, [dataType, metric, channelId, startDate, endDate]);

  const handlePieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <div style={{ flex: '1 1 auto', display: 'flex', flexWrap: 'wrap' }}>
        {data.map((yearData, index) => (
          <div key={index} style={{ flex: '1 1 33%', height: '400px', padding: '10px' }}>
            <YearlyPieChart yearData={yearData} activeIndex={activeIndex} onPieEnter={handlePieEnter} />
          </div>
        ))}
      </div>
      <Legend />
    </div>
  );
};

const YearlyPieChart = ({ yearData, activeIndex, onPieEnter }) => {
  const sortedData = yearData.data.sort((a, b) => monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={(props) => renderActiveShape({ ...props, year: yearData.year })}
          data={sortedData.map(d => ({ ...d, value: Math.abs(d.value) }))}
          cx="50%"
          cy="50%"
          startAngle={90}  // Adjusted start angle to make April at the 12-hour mark
          endAngle={-270}  // Adjusted end angle for clockwise direction
          innerRadius={30}  // Adjusted inner radius to make the white space smaller
          outerRadius={80}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {sortedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default RainfallCirclePlot;
