import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../css/MapComponent.css';
import L from 'leaflet';
import moment from 'moment';
import limpopoBasin from '../assets/limpopo_basin.json'; // Importing the Limpopo Basin GeoJSON file
import limpopoRiver from '../assets/limpopo_river.json'; // Importing the Limpopo River GeoJSON file
import dwsLimpopoBasinRivers from '../assets/dws_limpopo_basin_rivers.json'; // Importing the DWS Limpopo Basin Rivers GeoJSON file

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const legend = [
  { label: "None", color: "#2c7a2c" },
  { label: "Warning", color: "#e1ad01" },
  { label: "Alert Level 1", color: "#d4af37" },
  { label: "Alert Level 2", color: "#ff8c00" },
  { label: "Alert Level 3", color: "#cd3700" },
  { label: "Alert Level 4", color: "#b22222" },
  { label: "Alert Level 5", color: "#5c0a0a" }
];

const LegendControl = () => {
  const map = useMap();

  useEffect(() => {
    const legendControl = L.control({ position: 'bottomright' });

    legendControl.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      div.innerHTML = '<h4>Alert Type</h4>';
      legend.forEach(item => {
        div.innerHTML += `<i style="background: ${item.color}"></i> ${item.label}<br>`;
      });
      return div;
    };

    legendControl.addTo(map);

    return () => {
      legendControl.remove();
    };
  }, [map]);

  return null;
};

const DateLabelControl = ({ dateSelected }) => {
  const map = useMap();

  useEffect(() => {
    const dateControl = L.control({ position: 'topleft' });

    dateControl.onAdd = () => {
      const div = L.DomUtil.create('div', 'date-label');
      div.innerHTML = `<h4>${moment(dateSelected).format('MMMM YYYY')}</h4>`;
      return div;
    };

    dateControl.addTo(map);

    return () => {
      dateControl.remove();
    };
  }, [map, dateSelected]);

  return null;
};

const EFlowMapExport = ({ dateSelected }) => {
  const [geoData, setGeoData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const geoJsonLayer = useRef(null);

  const fetchEflowData = async (date) => {
    // Adjust the date to the last day of the month
    const lastDayOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');

    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/geo_eflow_ts?selected_date=${lastDayOfMonth}`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setGeoData(result);
    } catch (error) {
      console.error('Error fetching eflow data:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEflowData(dateSelected);
  }, [dateSelected]);

  useEffect(() => {
    if (geoJsonLayer.current && geoData) {
      geoJsonLayer.current.clearLayers().addData(geoData);
      adjustLabels();
    }
  }, [geoData]);

  const adjustLabels = () => {
    const labelElements = document.querySelectorAll('.leaflet-tooltip.river-code-label');
    const positions = [];

    labelElements.forEach((label) => {
      const rect = label.getBoundingClientRect();

      // Check if this label overlaps with any previously placed label
      const overlappingLabel = positions.find((pos) => {
        return (
          rect.left < pos.right &&
          rect.right > pos.left &&
          rect.top < pos.bottom &&
          rect.bottom > pos.top
        );
      });

      // If it overlaps, shift the label downwards
      if (overlappingLabel) {
        const newTop = overlappingLabel.bottom + 5; // Add some space
        label.style.top = `${newTop}px`;
      }

      // Store the position of this label
      positions.push({
        left: rect.left,
        right: rect.right,
        top: label.offsetTop, // Use offsetTop instead of rect.top to account for the element's relative position within the container
        bottom: label.offsetTop + rect.height,
      });
    });
  };

  const pointToLayer = (feature, latlng) => {
    const color = feature.properties.color || '#3388ff';
    const markerIcon = new L.Icon({
      iconUrl: `data:image/svg+xml;base64,${btoa(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12px" height="12px">
          <circle cx="12" cy="12" r="10" fill="${color}" stroke="${color}" stroke-width="2" />
        </svg>
      `)}`,
      iconSize: [24, 24],
      iconAnchor: [12, 24],
      popupAnchor: [0, -24],
    });
  
    // Create a marker with a permanent label showing the river code
    const marker = L.marker(latlng, { icon: markerIcon });
    marker.bindTooltip(feature.properties.river_code, { permanent: true, direction: 'left', className: 'river-code-label' });
    return marker;
  };

  return (
    <div className="EFlowMapExport"> 
      {loading && <div className="loading-spinner"></div>}
      <MapContainer 
        center={[-23.2, 30.4]} 
        zoom={6.8} 
        className="MapComponent"
        zoomSnap={0.25}  // Enable fractional zoom levels
        zoomDelta={0.25} // Control the step size when zooming in/out
      >
      <TileLayer
        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/">Humanitarian OpenStreetMap Team</a>'
      />
        {error && <p className="error-message">Error: {error.message}</p>}
        {geoData && (
          <GeoJSON key={dateSelected} ref={geoJsonLayer} data={geoData} pointToLayer={pointToLayer} />
        )}
        {/* Adding the Limpopo Basin GeoJSON Layer with grey outline and transparent fill */}
        <GeoJSON 
          data={limpopoBasin} 
          style={{ color: '#808080', weight: 2, fillColor: '#D3D3D3', fillOpacity: 0.5 }} 
        />
        {/* Adding the DWS Limpopo Basin Rivers GeoJSON Layer with light blue thin lines */}
        <GeoJSON 
          data={dwsLimpopoBasinRivers} 
          style={{ color: '#ADD8E6', weight: 1.5 }} 
        />
        {/* Adding the Limpopo River GeoJSON Layer with dark blue color */}
        <GeoJSON 
          data={limpopoRiver} 
          style={{ color: '#00008B', weight: 3 }} 
        />
        <LegendControl />
        <DateLabelControl dateSelected={dateSelected} />
      </MapContainer>
    </div>
  );
};

export default EFlowMapExport;
