import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import RealtimeChartComponent from './components/RealtimeChartComponent';
import RealtimeStationsComponent from './components/RealtimeStationsComponent';
import RainfallStationsComponent from './components/RainfallStationsComponent';
import RainfallChartComponent from './components/RainfallChartComponent';
import EflowSitesComponent from './components/EFlowSitesComponent';
import EflowChartComponent from './components/EFlowChartComponent';
import EFlowStatusMap from './components/EFlowStatusMap';
import SWATIncrementalMap from './components/SWATIncrementalMap';
import ReservoirStationsComponent from './components/ReservoirStationsComponent';
import ReservoirChartComponent from './components/ReservoirChartComponent';
import EflowChartExport from './components/EflowChartExport';
import RainfallChartExport from './components/RainfallChartExport';
import moment from 'moment';
import RainfallCirclePlot from './components/RainfallCirclePlot';
import Sidebar from './Sidebar';
import EflowMapExport from './components/EflowMapExport';
import RainfallMapExport from './components/RainfallMapExport';
import EFlowMapSimplePage from './components/EFlowMapSimplePage'; // Adjust the import path
import EFlowChartSimple from './components/EFlowChartSimple'; // Adjust the import path
import SimpleRainfallChart from './components/SimpleRainfallChart'; // Adjust the import path if necessary
import SwatNaturalFlowData from './components/SwatNaturalFlowData'; // Adjust path if necessary

function App() {
  const [unverifiedId, setUnverifiedId] = useState('');
  const [verifiedId, setVerifiedId] = useState('');
  const [selectedRainfallStation, setSelectedRainfallStation] = useState(null);
  const [selectedEflowSite, setSelectedEflowSite] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [dateSelected, setDateSelected] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [activeHeader, setActiveHeader] = useState('');
  const [selectedAnalysis, setSelectedAnalysis] = useState('');
  const [selectedParams, setSelectedParams] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const today = new Date();
    const lastYear = today.getFullYear() - 1;
    const defaultStartDate = new Date(lastYear, 9, 1);
    setStartDate(defaultStartDate);
  }, []);

  const handleStationSelect = (unverifiedId, verifiedId) => {
    setUnverifiedId(unverifiedId);
    setVerifiedId(verifiedId);
  };

  const handleRainfallStationSelect = (station) => {
    setSelectedRainfallStation(station);
  };

  const handleEflowSiteSelect = (site) => {
    setSelectedEflowSite(site);
  };

  const handleFetchData = () => {
    if (unverifiedId && verifiedId) {
      navigate('/realtime-chart');
    } else {
      alert('Please select a station first.');
    }
  };

  const handlePlotRainfall = () => {
    if (selectedRainfallStation) {
      navigate('/rainfall-chart');
    } else {
      alert('Please select a rainfall station first.');
    }
  };

  const handlePlotEflow = () => {
    if (selectedEflowSite) {
      navigate('/eflow-chart');
    } else {
      alert('Please select an Eflow site first.');
    }
  };

  const handlePlotRainfallCircleChart = () => {
    if (selectedRainfallStation) {
      navigate('/rainfall-circle-chart');
    } else {
      alert('Please select a rainfall station first.');
    }
  };

  const toggleHeader = (header) => {
    setActiveHeader((prevHeader) => (prevHeader === header ? '' : header));
  };

  const handleAnalysisSelect = (analysis) => {
    setSelectedAnalysis(analysis);
  };

  const handleParamsSelect = (params) => {
    setSelectedParams(params);
  };

  const handlePlotReservoirData = () => {
    if (selectedAnalysis && selectedParams.selectedOption) {
      navigate('/reservoir-levels');
    } else {
      alert('Please select the required parameters first.');
    }
  };

  const EflowChartDirect = () => {
    const [searchParams] = useSearchParams();
    const riverCode = searchParams.get('river_code');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');

    return (
      <EflowChartExport
        riverCode={riverCode}
        startDate={startDate}
        endDate={endDate}
      />
    );
  };

  const RainfallChartDirect = () => {
    const [searchParams] = useSearchParams();
    const stationName = searchParams.get('stationName');
    const channelId = searchParams.get('channelId');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    return (
      <RainfallChartExport
        stationName={stationName}
        channelId={channelId}
        startDate={startDate}
        endDate={endDate}
      />
    );
  };

  const EflowMapExportDirect = () => {
    const [searchParams] = useSearchParams();
    const date = searchParams.get('date');

    return (
      <EflowMapExport
        dateSelected={date}
      />
    );
  };

  const RainfallMapDirect = () => {
    const [searchParams] = useSearchParams();
    const minLongitude = searchParams.get('min_longitude');
    const maxLongitude = searchParams.get('max_longitude');
    const minLatitude = searchParams.get('min_latitude');
    const maxLatitude = searchParams.get('max_latitude');

    return (
      <RainfallMapExport
        minLongitude={minLongitude}
        maxLongitude={maxLongitude}
        minLatitude={minLatitude}
        maxLatitude={maxLatitude}
      />
    );
  };
  const EflowChartSimplePage = () => {
    const [searchParams] = useSearchParams();
    const riverCode = searchParams.get('river_code');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');

    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        <EFlowChartSimple
          riverCode={riverCode}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    );
  };
  // Define the routes where the Sidebar should not be displayed
  const noSidebarRoutes = ['/eflow-map-simple', '/eflow-chart-simple', '/rainfall-chart-simple'];

  return (
    <div className="App">
      {/* Conditionally render the Sidebar */}
      {!noSidebarRoutes.includes(location.pathname) && (
        <Sidebar
          activeHeader={activeHeader}
          toggleHeader={toggleHeader}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      )}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<EFlowStatusMap active dateSelected={dateSelected} setDateSelected={setDateSelected} className="MapComponent" />} />
          <Route path="/eflow" element={<EFlowStatusMap active dateSelected={dateSelected} setDateSelected={setDateSelected} className="MapComponent" />} />
          <Route path="/eflow-chart" element={
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EflowSitesComponent onSiteSelect={handleEflowSiteSelect} />
                <button className="btn btn-dark" onClick={handlePlotEflow} style={{ marginLeft: '10px' }}>Plot Eflow</button>
              </div>
              <hr />
              <div>
              {selectedEflowSite && startDate && endDate && (
                <EflowChartComponent
                  siteName={selectedEflowSite.name}
                  riverCode={selectedEflowSite.RiverCode}
                  description={selectedEflowSite.description}
                  startDate={startDate.toISOString().split('T')[0]}
                  endDate={endDate.toISOString().split('T')[0]}
                />
              )}
              </div>
            </>
          } />
          <Route path="/realtime-stations" element={
            <>
              <h1>Realtime Stations</h1>
              <RealtimeStationsComponent onStationSelect={handleStationSelect} />
              <button className="btn btn-dark" onClick={handleFetchData}>Load Chart</button>
            </>
          } />
          <Route path="/realtime-chart" element={  <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <RealtimeStationsComponent onStationSelect={handleStationSelect} />
                <button className="btn btn-dark" onClick={handleFetchData} style={{ marginLeft: '10px' }}>Plot All Discharge</button>
            </div>
            <hr />
            <div>
            {unverifiedId && verifiedId && (
              <RealtimeChartComponent unverifiedId={unverifiedId} verifiedId={verifiedId} />
            )} 
            </div>
          </>
          } />
          <Route path="/swat-natural-flow" element={<SwatNaturalFlowData />} /> {/* SWAT Natural Flow Route */}


          <Route path="/rainfall-stations" element={
            <>
              <h1>Rainfall Stations</h1>
              <RainfallStationsComponent onStationSelect={handleRainfallStationSelect} />
              <button className="btn btn-dark" onClick={handlePlotRainfall}>Plot Rainfall</button>
              <hr />
              <button className="btn btn-dark mt-2" onClick={handlePlotRainfallCircleChart}>View Rainfall Circle Chart</button>
            </>
          } />
          <Route path="/rainfall-chart" element={selectedRainfallStation && startDate && endDate && (
            <RainfallChartComponent
              stationName={selectedRainfallStation.station}
              channelId={selectedRainfallStation.channel_id}
              startDate={startDate.toISOString().split('T')[0]}
              endDate={endDate.toISOString().split('T')[0]}
              typeFilter=""
            />
          )} />
          <Route path="/swat-incremental" element={
            <>
              <h1>SWAT Incremental Map</h1>
              <SWATIncrementalMap active dateSelected={dateSelected} setDateSelected={setDateSelected} className="MapComponent" />
            </>
          } />
          <Route path="/rainfall-circle-chart" element={
            selectedRainfallStation && startDate && endDate && (
            <RainfallCirclePlot
              dataType="circle_plot"
              metric="Anomalies"
              channelId={selectedRainfallStation.channel_id}
              startDate={startDate.toISOString().split('T')[0]}
              endDate={endDate.toISOString().split('T')[0]}
            />
          )} />
          
          <Route
            path="/reservoir-levels"
            element={
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ReservoirStationsComponent
                    onAnalysisSelect={handleAnalysisSelect}
                    onParamsSelect={handleParamsSelect}
                  />
                  <button className="btn btn-dark" onClick={handlePlotReservoirData} style={{ marginLeft: '10px' }}>
                    Plot Data
                  </button>
                </div>
                <hr />
                <div>
                  {selectedAnalysis && selectedParams && startDate && endDate && (
                    <ReservoirChartComponent
                      analysisType={selectedAnalysis}
                      params={selectedParams}
                      startDate={startDate.toISOString().split('T')[0]}
                      endDate={endDate.toISOString().split('T')[0]}
                    />
                  )}
                </div>
              </>
            }
          />
          <Route path="/reservoir-chart" element={selectedAnalysis && selectedParams && startDate && endDate && (
            <ReservoirChartComponent
              analysisType={selectedAnalysis}
              params={selectedParams}
              startDate={startDate.toISOString().split('T')[0]}
              endDate={endDate.toISOString().split('T')[0]}
            />
          )} />
            <Route path="/" element={<EFlowStatusMap active dateSelected={dateSelected} setDateSelected={setDateSelected} className="MapComponent" />} />
            <Route path="/eflow-chart-direct" element={<EflowChartDirect />} />
            <Route path="/rainfall-chart-direct" element={<RainfallChartDirect />} />
            <Route path="/eflow-map-direct" element={<EflowMapExportDirect />} />
            <Route path="/rainfall-map-direct" element={<RainfallMapDirect />} />
            <Route path="/eflow-map-simple" element={<EFlowMapSimplePage />} />
            <Route path="/eflow-chart-simple" element={<EflowChartSimplePage />} />
            <Route path="/rainfall-chart-simple" element={<SimpleRainfallChart />} />

            <Route path="*" element={<div>Page not found</div>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
