import React, { useState, useEffect } from 'react';
import { getToken } from './auth';
import '../css/RealtimeStationsComponent.css';

const EflowSitesComponent = ({ onSiteSelect }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedSite, setSelectedSite] = useState('');

    const fetchData = async () => {
        try {
            const token = await getToken();

            const response = await fetch(`${process.env.REACT_APP_API_URL}/eflow_sites`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSiteChange = (e) => {
        const selectedId = e.target.value;
        setSelectedSite(selectedId);
        const selectedSiteData = data.find(site => site.RiverCode === selectedId);
        if (selectedSiteData) {
            onSiteSelect(selectedSiteData);
        }
    };

    return (
        <div className="station-select-container">
            {error && <p className="error-message">Error: {error.message}</p>}
            {data.length > 0 ? (
                <select onChange={handleSiteChange} value={selectedSite} className="station-select">
                    <option value="" disabled>Select a site</option>
                    {data.map(site => (
                        <option key={site.RiverCode} value={site.RiverCode}>
                            {site.RiverCode}
                        </option>
                    ))}
                </select>
            ) : (
                <p className="loading-message">Loading...</p>
            )}
        </div>
    );
};

export default EflowSitesComponent;
