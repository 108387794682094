import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Tab, Tabs, InputGroup } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import * as XLSX from 'xlsx';
import { getToken } from '../components/auth';

// Fix for default marker icon path
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const SwatNaturalFlowDataModal = () => {
  const [show, setShow] = useState(false);
  const [channelId, setChannelId] = useState('');
  const [channels, setChannels] = useState([]);
  const [startDate, setStartDate] = useState('2000-01-01');
  const [endDate, setEndDate] = useState('2024-01-01');
  const [condition, setCondition] = useState('all');
  const [flowType, setFlowType] = useState('natural'); // Toggle for flow type
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [excelBlob, setExcelBlob] = useState(null);
  const [mapCenter, setMapCenter] = useState([-25.7479, 28.2293]); // Default map center (Pretoria, South Africa)
  const [showNavigateButton, setShowNavigateButton] = useState(false); // For revealing "Navigate to Probflo" button
  const [selectedMarker, setSelectedMarker] = useState(null); // For keeping track of the selected marker
  const [activeTab, setActiveTab] = useState('map'); // Active tab state

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Fetch the list of channels from the API
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const token = await getToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/spatial/swat_channels`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch channels');
        }

        const result = await response.json();
        setChannels(result); // Store the channel list in state
        if (result[0]?.latitude && result[0]?.longitude) {
          setChannelId(result[0]?.channel_id || '');
          setMapCenter([result[0]?.latitude, result[0]?.longitude]); // Center the map on the first channel
        }
      } catch (error) {
        console.error('Error fetching channels:', error);
        setError('Error fetching channel list');
      }
    };

    fetchChannels();
  }, []);

  // Custom component to move the map when the channel changes
  const ChangeMapView = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(center, 12); // Adjust zoom level as needed
    }, [center, map]);
    return null;
  };

  // When a channel is selected from the dropdown
  const handleChannelChange = (e) => {
    const selectedChannel = channels.find((channel) => channel.channel_id === e.target.value);
    setChannelId(selectedChannel.channel_id);

    if (selectedChannel.latitude && selectedChannel.longitude) {
      setMapCenter([selectedChannel.latitude, selectedChannel.longitude]); // Center the map on the selected channel
      setSelectedMarker(selectedChannel); // Set the selected marker for popup
    }
  };

  // When a marker is clicked on the map
  const handleMarkerClick = (channel) => {
    setChannelId(channel.channel_id); // Update the dropdown with the selected marker
    setMapCenter([channel.latitude, channel.longitude]); // Center the map on the selected channel
    setSelectedMarker(channel); // Set the selected marker for popup
  };

  const handleFetchData = async () => {
    setLoading(true);
    setError(null);

    // Select API based on flow type
    const apiEndpoint =
      flowType === 'natural' ? '/probflo/swat_natural_excel' : '/probflo/swat_present_excel';

    const queryParams = new URLSearchParams({
      channel_id: channelId,
      start_date: startDate,
      end_date: endDate,
      condition: condition,
    }).toString();

    try {
      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}${apiEndpoint}?${queryParams}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      setExcelBlob(blob);

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        setData(sheet);
        setActiveTab('table'); // Switch to table view
      };
      fileReader.readAsArrayBuffer(blob);

      setShowNavigateButton(true); // Show the "Navigate to Probflo" button after fetching data
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    if (excelBlob) {
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'swat_natural_flow_data.xlsx';
      a.click();
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Open SWAT Extraction Tool
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>SWAT Extraction Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          <Col md={4} style={{ maxHeight: '560px', overflowY: 'auto' }}>
          <Form>
              <Row className="form-group">
                <Col md={4}>
                  <Form.Label>Select Flow Type:</Form.Label>
                </Col>
                <Col md={8}>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="fa fa-water" /> {/* Add your icon here */}
                    </InputGroup.Text>
                    <Form.Control
                      as="select"
                      value={flowType}
                      onChange={(e) => setFlowType(e.target.value)}
                      className="uniform-width-input"
                    >
                      <option value="natural">Natural Flows</option>
                      <option value="present">Present-Day Flows</option>
                    </Form.Control>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="form-group">
                <Col md={4}>
                  <Form.Label>Channel ID:</Form.Label>
                </Col>
                <Col md={8}>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="fa fa-code" /> {/* Add your icon here */}
                    </InputGroup.Text>
                    <Form.Control
                      as="select"
                      value={channelId}
                      onChange={handleChannelChange}
                      className="uniform-width-input"
                    >
                      {channels.map((channel) => (
                        <option key={channel.channel_id} value={channel.channel_id}>
                          {channel.channel_name} ({channel.channel_id})
                        </option>
                      ))}
                    </Form.Control>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="form-group">
                <Col md={4}>
                  <Form.Label>Start Date:</Form.Label>
                </Col>
                <Col md={8}>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="fa fa-calendar" /> {/* Add your icon here */}
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="uniform-width-input"
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Row className="form-group">
                <Col md={4}>
                  <Form.Label>End Date:</Form.Label>
                </Col>
                <Col md={8}>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="fa fa-calendar" /> {/* Add your icon here */}
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="uniform-width-input"
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Row className="form-group">
                <Col md={4}>
                  <Form.Label>Condition:</Form.Label>
                </Col>
                <Col md={8}>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="fa fa-thermometer-half" /> {/* Add your icon here */}
                    </InputGroup.Text>
                    <Form.Control
                      as="select"
                      value={condition}
                      onChange={(e) => setCondition(e.target.value)}
                      className="uniform-width-input"
                    >
                      <option value="all">All</option>
                      <option value="normal">Normal</option>
                      <option value="drought">Drought</option>
                      <option value="wet">Wet</option>
                    </Form.Control>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="form-group">
              <Col md={12} className="d-flex justify-content-center">
                  <Button
                    variant="success"
                    onClick={handleFetchData}
                    disabled={loading}
                    className="uniform-width-button"
                  >
                    {loading ? 'Fetching...' : 'Fetch Data'}
                  </Button>
                </Col>

              </Row>
            </Form>
          </Col>
          <Col md={8}>
           <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-3"
              >
                <Tab eventKey="map" title="Map">
                  <MapContainer
                    center={mapCenter}
                    zoom={7}
                    scrollWheelZoom={true}
                    style={{ height: '500px', width: '100%' }}
                  >
                    <ChangeMapView center={mapCenter} />
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {channels.map((channel) =>
                      channel.latitude && channel.longitude ? (
                        <Marker
                          key={channel.channel_id}
                          position={[channel.latitude, channel.longitude]}
                          eventHandlers={{
                            click: () => handleMarkerClick(channel),
                          }}
                        >
                          <Popup open={selectedMarker?.channel_id === channel.channel_id}>
                            {channel.channel_name} <br /> ({channel.channel_id})
                          </Popup>
                        </Marker>
                      ) : null
                    )}
                  </MapContainer>
                </Tab>
                <Tab eventKey="table" title="Table">
                  {data && (
                    <div style={{ marginTop: '30px', maxHeight: '400px', overflowY: 'auto' }}>
                      {channels
                        .filter(channel => channel.channel_id === channelId) // Filter to show only the selected channel
                        .map((channel) => (
                          <h5 key={channel.channel_id}>
                            Percentile  for channel {channel.channel_id} on the {channel.channel_name} River
                          </h5>
                        ))
                      }
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {Object.keys(data[0]).map((key) => (
                              <th key={key}>{key}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((row, index) => (
                            <tr key={index}>
                              {Object.values(row).map((val, idx) => (
                                <td key={idx}>{val}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="help" title="Help">
                <div className="container mt-4">
                  <h5>Instructions for Using the SWAT Extraction Tool</h5>
                  <p>
                    The SWAT Extraction Tool allows users to extract natural and present-day flow data simulated by the SWAT model for use in the Probflo Risk Assessment Tool. 
                    It provides access to hydrological data enabling users to download the data in a specific format required by probflo. 
                    The tool supports filtering by hydrological conditions for specific channels and time periods. 
                  </p>
                  <h6>Steps to use the SWAT Extraction Tool:</h6>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item"><strong>Step 1:</strong> Choose the desired channel from the list.</li>
                    <li className="list-group-item"><strong>Step 2:</strong> Input the start and end dates for the time period of interest.</li>
                    <li className="list-group-item"><strong>Step 3:</strong> Choose a hydrological condition (e.g., all, normal, drought, or wet).</li>
                    <li className="list-group-item"><strong>Step 4:</strong> Click the button to retrieve flow data based on the selected parameters.</li>
                    <li className="list-group-item"><strong>Step 5:</strong> Once the data is fetched, download it in Excel format.</li>
                    <li className="list-group-item"><strong>Step 6:</strong>Navigate to Probflo</li>
                    <li className="list-group-item"><strong>Step 7:</strong> Upload the Excel file into Probflo to use it for risk assessments.</li>
                    <li className="list-group-item"><strong>Step 8:</strong> Create a case file within Probflo using the uploaded data.</li>
                    <li className="list-group-item"><strong>Step 9:</strong> Save the generated case file for future use.</li>
                    <li className="list-group-item"><strong>Step 10:</strong> Navigate to the graphs section within Probflo.</li>
                    <li className="list-group-item"><strong>Step 11:</strong> Load the saved case file to visualize the results.</li>
                    <li className="list-group-item"><strong>Step 12:</strong> Analyze the graphs to understand the ecological risks and projected impacts.</li>
                  </ul>
                </div>
                </Tab>
                <Tab eventKey="probflo" title="Probflo">
                  <iframe 
                      src="https://probflo.riversoflife.co.za/" 
                      title="Probflo" 
                      width="100%" 
                      height="600px" 
                      style={{ border: 'none' }}
                    />
                </Tab>
              </Tabs>
            </Col>
            </Row>
          {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
        </Modal.Body>


        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {excelBlob && (
            <Button
              variant="success"
              onClick={() => {
                handleDownload(); // Trigger the download function
                setShowNavigateButton(true); // Show the "Navigate to Probflo" button after this button is clicked
              }}
            >
              Download Excel File
            </Button>
          )}
          {showNavigateButton && (
            <Button
              variant="info"
              href="https://probflo.riversoflife.co.za/"
              target="_blank"
            >
              Navigate to Probflo
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SwatNaturalFlowDataModal;
