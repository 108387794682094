import React, { useState, useEffect } from 'react';

const ReservoirStationsComponent = ({ onAnalysisSelect, onParamsSelect }) => {
    const [error, setError] = useState(null);
    const [selectedAnalysis, setSelectedAnalysis] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [percentInvalid, setPercentInvalid] = useState(10);
    const [analysisOptions, setAnalysisOptions] = useState([]);

    useEffect(() => {
        if (selectedAnalysis) {
            fetchOptions(selectedAnalysis);
        }
    }, [selectedAnalysis]);

    const fetchOptions = async (analysisType) => {
        let endpoint = '';

        switch (analysisType) {
            case 'Water Area Timeseries':
            case 'Aggregated Water Area Timeseries':
                endpoint = 'reservoir_list';
                break;
            case 'DEA Water Area Timeseries by ID':
                endpoint = 'original_id_list';
                break;
            case 'Aggregated Water Area Timeseries by Channel':
            case 'Yearly Aggregated Timeseries by Channel':
                endpoint = 'channel_list';
                break;
            default:
                setAnalysisOptions([]);
                return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/reservoir_options?options_type=${endpoint}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setAnalysisOptions(result);
        } catch (error) {
            console.error('Error fetching options:', error);
            setError(error);
        }
    };

    const handleAnalysisChange = (e) => {
        const selectedAnalysis = e.target.value;
        setSelectedAnalysis(selectedAnalysis);
        onAnalysisSelect(selectedAnalysis);
        setSelectedOption('');
        setAnalysisOptions([]);
    };

    const handleOptionChange = (e) => {
        const selectedOption = e.target.value;
        setSelectedOption(selectedOption);
        onParamsSelect({
            selectedOption,
            optionType: getOptionType(selectedAnalysis),
            percentInvalid
        });
    };

    const getOptionType = (analysisType) => {
        switch (analysisType) {
            case 'DEA Water Area Timeseries by ID':
                return 'original_id';
            case 'Water Area Timeseries':
            case 'Aggregated Water Area Timeseries':
                return 'name';
            case 'Aggregated Water Area Timeseries by Channel':
            case 'Yearly Aggregated Timeseries by Channel':
                return 'swat_channel';
            default:
                return '';
        }
    };

    return (
        <div className="station-select-container">
            {error && <p className="error-message">Error: {error.message}</p>}
            <div className="form-group">
                <label htmlFor="analysisType">Select Analysis Type:</label>
                <select onChange={handleAnalysisChange} value={selectedAnalysis} className="form-control">
                    <option value="" disabled>Select an analysis</option>
                    <option value="Water Area Timeseries">Water Area Timeseries</option>
                    <option value="Aggregated Water Area Timeseries">Aggregated Water Area Timeseries</option>
                    <option value="DEA Water Area Timeseries by ID">DEA Water Area Timeseries by ID</option>
                    <option value="Aggregated Water Area Timeseries by Channel">Aggregated Water Area Timeseries by Channel</option>
                    <option value="Yearly Aggregated Timeseries by Channel">Yearly Aggregated Timeseries by Channel</option>
                </select>
            </div>

            {selectedAnalysis && (
                <div className="form-group">
                    <label htmlFor="selectedOption">Select Option:</label>
                    <select onChange={handleOptionChange} value={selectedOption} className="form-control">
                        <option value="" disabled>Select an option</option>
                        {analysisOptions.map((option, index) => (
                            <option key={index} value={option[getOptionType(selectedAnalysis)]}>
                                {option[getOptionType(selectedAnalysis)]}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <div className="form-group">
                <label htmlFor="percentInvalid">Percent Invalid:</label>
                <input
                    type="number"
                    id="percentInvalid"
                    value={percentInvalid}
                    onChange={(e) => setPercentInvalid(e.target.value)}
                    className="form-control"
                />
            </div>
        </div>
    );
};

export default ReservoirStationsComponent;
