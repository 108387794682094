import React from 'react';
import { useSearchParams } from 'react-router-dom';
import EFlowMapExport from './EflowMapExport'; // Adjust the import path if necessary

const EFlowMapSimplePage = () => {
  const [searchParams] = useSearchParams();
  const dateSelected = searchParams.get('date') || "2023-10-01"; // Default value

  return (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <EFlowMapExport
          dateSelected={dateSelected}
        />
      </div>
      {/* Inline style override */}
      <style>
        {`
          .EFlowMapExport,
          .EFlowMapExport > .MapComponent,
          .EFlowMapExport > .MapComponent > div,
          .EFlowMapExport > .MapComponent > div > div {
            height: 100% !important;
            width: 100% !important;
            max-height: none !important;
            max-width: none !important;
          }
        `}
      </style>
    </div>
  );
};

export default EFlowMapSimplePage;
