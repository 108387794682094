import axios from 'axios';

export const getToken = async () => {
    const response = await axios.post(process.env.REACT_APP_POST_URL, {
        username: process.env.REACT_APP_USERNAME,
        password: process.env.REACT_APP_PASSWORD,
    });

    if (response.status === 200) {
        console.log('Token obtained');
        return response.data.access_token;
    } else {
        console.error(`Failed to obtain token: ${response.status} ${response.statusText}`);
        throw new Error('Failed to obtain token');
    }
};