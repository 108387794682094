import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getToken } from '../components/auth';
import moment from 'moment';
import { toPng } from 'html-to-image';
import '../css/RealtimeChartComponent.css';
import logo from '../iwmi_dws_logo.png';

const RealtimeChartComponent = ({ unverifiedId, verifiedId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [visibleSeries, setVisibleSeries] = useState({
        min: true,
        ninety_fifth: true,
        seventy_fifth: true,
        fiftieth: true,
        twenty_fifth: true,
        fifth: true,
        max: true,
        realtime_discharge: true,
        verified_discharge: true,
        verified_monthly: true,
        iwmi_swat_output: true
    });
    const chartRef = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            const token = await getToken();

            const start_date = '2024-09-01';
            const end_date = '2024-12-31';

            const queryParams = new URLSearchParams({
                unverified_station_id: unverifiedId,
                verified_station_id: verifiedId,
                start_date,
                end_date
            }).toString();

            const response = await fetch(`${process.env.REACT_APP_API_URL}/unverified_chart?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
        }
    }, [unverifiedId, verifiedId]);

    useEffect(() => {
        if (unverifiedId && verifiedId) {
            fetchData();
        }
    }, [unverifiedId, verifiedId, fetchData]);

    const processDataForChart = (data) => {
        return data.map(d => ({
            ...d,
            date: new Date(d.date).valueOf()
        }));
    };

    const processedData = data ? processDataForChart(data) : [];

    const handleToggleSeries = (series) => {
        setVisibleSeries(prevState => ({ ...prevState, [series]: !prevState[series] }));
    };

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <div className="custom-legend" style={{ fontSize: '12px' }}>
                {
                    payload.map((entry, index) => {
                        const isSquare = ['min', 'ninety_fifth', 'seventy_fifth', 'fiftieth', 'twenty_fifth', 'fifth', 'max'].includes(entry.dataKey);
                        return (
                            <span key={`item-${index}`} style={{ marginRight: 10, color: entry.color, display: 'inline-flex', alignItems: 'center', fontSize: '12px' }}>
                                {isSquare ? (
                                    <svg width="10" height="10" style={{ marginRight: 5 }}>
                                        <rect width="10" height="10" fill={entry.color} />
                                    </svg>
                                ) : (
                                    <svg width="20" height="10" style={{ marginRight: 5 }}>
                                        <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="2" />
                                    </svg>
                                )}
                                {entry.value}
                            </span>
                        );
                    })
                }
            </div>
        );
    };

    const handleExport = () => {
        if (chartRef.current) {
            toPng(chartRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = 'chart.png';
                    link.href = dataUrl;
                    link.click();
                })
                .catch((error) => {
                    console.error('Error exporting chart:', error);
                });
        }
    };

    return (
        <div className="chart-container">
            {error && <p>Error: {error.message}</p>}
            {data ? (
                <div>
                    <p className="fs-4">
                        Historical and Forecasted Discharge for Station: <span className="station-id">{verifiedId}</span>
                    </p>
                    <div className="button-container">
                        <button onClick={handleExport}>Export Chart</button>
                        <button className={visibleSeries.min ? 'active' : ''} onClick={() => handleToggleSeries('min')}>Very Low</button>
                        <button className={visibleSeries.ninety_fifth ? 'active' : ''} onClick={() => handleToggleSeries('ninety_fifth')}>Moderately Low</button>
                        <button className={visibleSeries.seventy_fifth ? 'active' : ''} onClick={() => handleToggleSeries('seventy_fifth')}>Low</button>
                        <button className={visibleSeries.fiftieth ? 'active' : ''} onClick={() => handleToggleSeries('fiftieth')}>Normal</button>
                        <button className={visibleSeries.twenty_fifth ? 'active' : ''} onClick={() => handleToggleSeries('twenty_fifth')}>High</button>
                        <button className={visibleSeries.fifth ? 'active' : ''} onClick={() => handleToggleSeries('fifth')}>Moderately High</button>
                        <button className={visibleSeries.max ? 'active' : ''} onClick={() => handleToggleSeries('max')}>Very High</button>
                        <button className={visibleSeries.realtime_discharge ? 'active' : ''} onClick={() => handleToggleSeries('realtime_discharge')}>Unverified</button>
                        <button className={visibleSeries.verified_discharge ? 'active' : ''} onClick={() => handleToggleSeries('verified_discharge')}>Last Year</button>
                        <button className={visibleSeries.verified_monthly ? 'active' : ''} onClick={() => handleToggleSeries('verified_monthly')}>Verified Monthly</button>
                        <button className={visibleSeries.iwmi_swat_output ? 'active' : ''} onClick={() => handleToggleSeries('iwmi_swat_output')}>IWMI SWAT</button>
                    </div>
                    <div ref={chartRef} style={{height: "60vh", width: "80vw"}}>
                        <ResponsiveContainer width="100%" height={550}>
                            <ComposedChart data={processedData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    scale="time"
                                    type="number"
                                    domain={['auto', 'auto']}
                                    tickFormatter={(unixTime) => moment(unixTime).format('YYYY-MM-DD')}
                                    tick={{ fontSize: '14px', fontFamily: 'Arial' }}
                                />
                                <YAxis
                                    label={{ value: 'Discharge (m³.s⁻¹)', angle: -90, position: 'insideLeft', style: { fontSize: '12px' } }}
                                    type="number"
                                    domain={['auto', 'auto']}
                                    tick={{ fontSize: '12px', fontFamily: 'Arial' }}
                                />
                                <Tooltip />
                                <Legend content={renderLegend} />
                                {visibleSeries.min && <Area stackId="1" name="Very Low" type="monotone" dataKey="min" stroke="#FF0000" fill="#FF0000" fillOpacity={0.05} />}
                                {visibleSeries.ninety_fifth && <Area stackId="1" name="Moderately Low" type="monotone" dataKey="ninety_fifth" stroke="#FFCC00" fill="#FFCC00" fillOpacity={0.05} />}
                                {visibleSeries.seventy_fifth && <Area stackId="1" name="Low" type="monotone" dataKey="seventy_fifth" stroke="#d4d424" fill="#d4d424" fillOpacity={0.05} />}
                                {visibleSeries.fiftieth && <Area stackId="1" name="Normal" type="monotone" dataKey="fiftieth" stroke="#99CC33" fill="#99CC33" fillOpacity={0.05} />}
                                {visibleSeries.twenty_fifth && <Area stackId="1" name="High" type="monotone" dataKey="twenty_fifth" stroke="#3399ff" fill="#3399ff" fillOpacity={0.05} />}
                                {visibleSeries.fifth && <Area stackId="1" name="Moderately High" type="monotone" dataKey="fifth" stroke="#67b4e0" fill="#67b4e0" fillOpacity={0.05} />}
                                {visibleSeries.max && <Area stackId="1" name="Very High" type="monotone" dataKey="max" stroke="#0033cc" fill="#0033cc" fillOpacity={0.05} />}
                                {visibleSeries.realtime_discharge && <Line name="Unverified" type="monotone" dataKey="realtime_discharge" stroke="#000000" strokeWidth={3} dot={false} />}
                                {visibleSeries.verified_discharge && <Line name="Verified Last Year" type="monotone" dataKey="verified_discharge" stroke="#858585" strokeDasharray="5 5" strokeWidth={1} dot={false} />}
                                {visibleSeries.verified_monthly && <Line name="Verified Monthly Avg" type="monotone" dataKey="verified_monthly" stroke="#00563F" strokeDasharray="5 5" strokeWidth={3} dot={false} />}
                                {visibleSeries.iwmi_swat_output && <Line name="SWAT Simulation Monthly Avg" type="monotone" dataKey="iwmi_swat_output" stroke="#ff7300" strokeWidth={3} dot={false} />}
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <img src={logo} alt="IWMI DWS Logo" className="logo" />
                </div>
            ) : (
                <div className="loading-spinner">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
        </div>
    );
};

export default RealtimeChartComponent;
