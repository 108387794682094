import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { toPng } from 'html-to-image';
import { getToken } from './auth';
import '../css/RealtimeChartComponent.css';
import logo from '../iwmi_dws_logo.png';

const RainfallChartComponent = ({ stationName, channelId, startDate, endDate, typeFilter }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [visibleSeries, setVisibleSeries] = useState({
        historical: true,
        forecast: true,
    });
    const chartRef = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            const token = await getToken();

            const queryParams = new URLSearchParams({
                station_name: stationName,
                channel_id: channelId,
                start_date: startDate,
                end_date: endDate,
                type_filter: typeFilter
            }).toString();

            console.log('Fetching data with params:', queryParams);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/rainfall_chart?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
        }
    }, [stationName, channelId, startDate, endDate, typeFilter]);

    useEffect(() => {
        if (stationName && startDate && endDate) {
            fetchData();
        }
    }, [stationName, startDate, endDate, typeFilter, fetchData]);

    const processDataForChart = (data) => {
        return data.map(d => ({
            ...d,
            date: new Date(d.date).valueOf()
        }));
    };

    const processedData = data ? processDataForChart(data) : [];

    const handleToggleSeries = (series) => {
        setVisibleSeries(prevState => ({ ...prevState, [series]: !prevState[series] }));
    };

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <div className="custom-legend" style={{ fontSize: '12px' }}>
                {
                    payload.map((entry, index) => {
                        const color = entry.dataKey === 'historical' ? '#000000' : entry.dataKey === 'forecast' ? '#FF0000' : entry.color;
                        return (
                            <span key={`item-${index}`} style={{ marginRight: 10, color: color, display: 'inline-flex', alignItems: 'center', fontSize: '12px' }}>
                                <svg width="12" height="12" style={{ marginRight: 5 }}>
                                    <rect width="12" height="12" fill={color} />
                                </svg>
                                {entry.value}
                            </span>
                        );
                    })
                }
            </div>
        );
    };
    
    

    const handleExport = () => {
        if (chartRef.current) {
            toPng(chartRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = 'chart.png';
                    link.href = dataUrl;
                    link.click();
                })
                .catch((error) => {
                    console.error('Error exporting chart:', error);
                });
        }
    };

    return (
        <div className="chart-container">
            {error && <p>Error: {error.message}</p>}
            {data ? (
                <div>
                    <h2 className="chart-title">
                        Historical and Forecasted Rainfall for Channel: <span className="channel-id">{channelId}</span>
                    </h2>
                    <div className="y-axis-input-container">
                        <div className="button-container">
                            <button onClick={handleExport}>Export Chart</button>
                        </div>
                    </div>
                    <div className="button-container">
                        <button
                            className={visibleSeries.historical ? 'active' : ''}
                            onClick={() => handleToggleSeries('historical')}
                        >
                            Historical
                        </button>
                        <button
                            className={visibleSeries.forecast ? 'active' : ''}
                            onClick={() => handleToggleSeries('forecast')}
                        >
                            Forecast
                        </button>
                    </div>
                    <div ref={chartRef} style={{height: "60vh", width: "80vw"}}>
                    <ResponsiveContainer width="100%" height={550}>
                            <ComposedChart data={processedData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    scale="time"
                                    type="number"
                                    domain={['auto', 'auto']}
                                    tickFormatter={(unixTime) => moment(unixTime).format('YYYY-MM-DD')}
                                    tick={{ fontSize: '14px', fontFamily: 'Arial' }}
                                />
                                <YAxis
                                    label={{ value: 'Precipitation (mm)', angle: -90, position: 'insideLeft', style: { fontSize: '12px' } }}
                                    type="number"
                                    domain={['auto', 'auto']}
                                    tick={{ fontSize: '12px', fontFamily: 'Arial' }}
                                />
                                <Tooltip />
                                <Legend content={renderLegend} />
                                {visibleSeries.historical && <Bar name="Historical" type="monotone" dataKey="historical" stroke="#000000" strokeWidth={3} dot={false} />}
                                {visibleSeries.forecast && <Bar name="Forecast" type="monotone" dataKey="forecast" stroke="#FF0000" strokeWidth={3} dot={false} />}
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ) : (
                <div className="loading-spinner">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
        </div>
    );
};

export default RainfallChartComponent;
