import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../css/MapComponent.css';
import InputSlider from 'react-input-slider';
import L from 'leaflet';
import moment from 'moment';
import _ from 'lodash';
import rawSwatChannels from '../assets/swat_channels.geojson'; // Import the raw GeoJSON file

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const legend = [
  { label: "High Positive Contribution", color: "#08306b" },
  { label: "Medium Positive Contribution", color: "#2171b5" },
  { label: "Low Positive Contribution", color: "#6baed6" },
  { label: "No Contribution", color: "#bdd7e7" },
  { label: "Low Negative Contribution", color: "#fee0d2" },
  { label: "Medium Negative Contribution", color: "#fc9272" },
  { label: "High Negative Contribution", color: "#de2d26" }
];

const LegendControl = () => {
  const map = useMap();

  useEffect(() => {
    const legendControl = L.control({ position: 'bottomright' });

    legendControl.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      div.innerHTML = '<h4>Incremental Contribution</h4>';
      legend.forEach(item => {
        div.innerHTML += `<i style="background: ${item.color}"></i> ${item.label}<br>`;
      });
      return div;
    };

    legendControl.addTo(map);

    return () => {
      legendControl.remove();
    };
  }, [map]);

  return null;
};

const SWATIncrementalMap = ({ dateSelected, setDateSelected }) => {
  const [geoData, setGeoData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sliderValue, setSliderValue] = useState(moment(dateSelected).diff(moment('2001-01-01'), 'months'));
  const geoJsonLayer = useRef(null);
  const previousDateRef = useRef(dateSelected);

  const fetchSwatData = async (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    //console.log(`Fetching data for date: ${formattedDate}`);
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/swat_incremental_flow?selected_date=${formattedDate}`);
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const textResponse = await response.text();
        throw new Error(`Expected JSON but received: ${textResponse}`);
      }
  
      const swatData = await response.json();
      // Parse the imported raw GeoJSON string as JSON
      const channelsData = JSON.parse(rawSwatChannels);
  
      const mergedGeoJson = mergeGeoJson(channelsData, swatData);
      setGeoData(mergedGeoJson);
    } catch (error) {
      console.error('Error fetching swat data:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const mergeGeoJson = (baseGeoJson, additionalGeoJson) => {
    // Ensure both GeoJSON objects are defined and have features
    if (!baseGeoJson || !baseGeoJson.features || !additionalGeoJson || !additionalGeoJson.features) {
      throw new Error('Invalid GeoJSON data');
    }

    const additionalFeaturesMap = new Map();
    additionalGeoJson.features.forEach(feature => {
      additionalFeaturesMap.set(feature.properties.ChannelR, feature);
    });

    const mergedFeatures = baseGeoJson.features.map(feature => {
      const channelId = feature.properties.ChannelR;
      if (additionalFeaturesMap.has(channelId)) {
        const additionalFeature = additionalFeaturesMap.get(channelId);
        feature.properties.incremental_contribution = additionalFeature.properties.incremental_contribution;
        feature.properties.color = additionalFeature.properties.color;
      }
      return feature;
    });

    return {
      ...baseGeoJson,
      features: mergedFeatures,
    };
  };

  const debouncedFetchSwatData = useRef(_.debounce((date) => {
    fetchSwatData(date);
  }, 500)).current;

  useEffect(() => {
    //console.log(`Use Effect Update to Selected Date: ${dateSelected}`);
    debouncedFetchSwatData(dateSelected);
    previousDateRef.current = dateSelected;
  }, [dateSelected, debouncedFetchSwatData]);

  useEffect(() => {
    if (geoJsonLayer.current && geoData) {
      geoJsonLayer.current.clearLayers().addData(geoData);
    }
  }, [geoData]);

  const handleDateChange = ({ x }) => {
    const newDate = moment('2001-01-01').add(x, 'months').endOf('month').format('YYYY-MM-DD');
    //console.log(`Slider changed, new date: ${newDate}`);
    setSliderValue(x);
    setDateSelected(newDate);
  };

  const handleSliderDragEnd = () => {
    if (dateSelected !== previousDateRef.current) {
      previousDateRef.current = dateSelected;
    }
  };

  const styleFeature = (feature) => {
    return {
      color: feature.properties.color || '#3388ff',
      weight: 2,
      opacity: 1,
      fillOpacity: 0.7
    };
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      const popupContent = `
        <div>
          <strong>Channel ID:</strong> ${feature.properties.ChannelR}<br />
          <strong>Incremental Contribution:</strong> ${feature.properties.incremental_contribution}
        </div>
      `;
      layer.bindPopup(popupContent);
    }
  };

  return (
    <div className="SWATIncrementalMap">
      {loading && <div className="loading-spinner"></div>}
      <MapContainer center={[-23.5, 29.5]} zoom={7} className="MapComponent">
        <TileLayer
          url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://opentopomap.org">OpenTopoMap</a> contributors, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          opacity={0.5} // Adjust opacity as needed
        />
        {error && <p className="error-message">Error: {error.message}</p>}
        {geoData && (
          <GeoJSON key={dateSelected} ref={geoJsonLayer} data={geoData} style={styleFeature} onEachFeature={onEachFeature} />
        )}
        <LegendControl />
      </MapContainer>

      <div className="slider-container">
        <InputSlider
          axis="x"
          x={sliderValue}
          xmin={0}
          xmax={moment('2024-12-31').diff(moment('2001-01-01'), 'months')}
          xstep={1}
          onChange={handleDateChange}
          onDragEnd={handleSliderDragEnd}
          styles={{
            track: {
              backgroundColor: 'lightgrey',
              height: '4px',
              width: '100%'
            },
            active: {
              backgroundColor: '#007bff',
              height: '4px',
              width: '100%'
            },
            thumb: {
              width: '20px',
              height: '20px',
              backgroundColor: '#ffffff',
              border: '2px solid #007bff',
              boxShadow: '0 0 2px rgba(0,0,0,0.2)'
            },
            sliderLabel: {
              backgroundColor: '#007bff',
              color: '#ffffff',
              padding: '2px 8px',
              borderRadius: '4px'
            }
          }}
        />
        <div className="slider-label">
          {moment('2001-01-01').add(sliderValue, 'months').endOf('month').format('YYYY-MM-DD')}
        </div>
      </div>
    </div>
  );
};

export default SWATIncrementalMap;
