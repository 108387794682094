import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import IWMI from './img/iwmi.png';
import AWARD from './img/award.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Sidebar = ({
  activeHeader,
  toggleHeader,
  activeTab,
  setActiveTab,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) => {
  return (
    <div style={{ display: 'flex', overflow: 'scroll initial' }}>
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <p className="fs-6" href="/" style={{ color: 'inherit' }}>
            LIMCOM DT Visualisations
          </p>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content" style={{ padding: '0px', display: 'flex', flexDirection: 'column' }}>
          
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="fa-regular fa-calendar-days">
               <DatePicker
                  id="startDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  aria-describedby="startText"
                />
                <p className="fs-6">Start date</p>
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem icon="fa-solid fa-calendar-week">
                <DatePicker
                  id="endDate"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  aria-describedby="endText"
                />
                <p className="fs-6">End date</p>
              </CDBSidebarMenuItem>
            <hr style={{ borderColor: '#fff' }} />
            <NavLink exact to="/realtime-stations" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="tachometer-alt">Realtime Stations</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/realtime-chart" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">Discharge All Available</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/discharge-swat" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="project-diagram">Discharge SWAT</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/reservoir-levels" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="water">Reservoir Levels</CDBSidebarMenuItem>
            </NavLink>
            <hr style={{ borderColor: '#fff' }} />
            <NavLink exact to="/swat-incremental" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="balance-scale">SWAT Incremental</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/swat-natural-flow" activeClassName="activeClicked"> {/* New SWAT Natural Flow Link */}
              <CDBSidebarMenuItem icon="chart-line">SWAT Natural Flow Data</CDBSidebarMenuItem>
            </NavLink>
            <hr style={{ borderColor: '#fff' }} />
            <NavLink exact to="/rainfall-stations" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="cloud-rain">Rainfall Stations</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/rainfall-chart" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-area">Rainfall Chart</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/rainfall-circle-chart" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-pie">Rainfall Circle Chart</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/anomalies-monthly" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="calendar-alt">Anomalies Monthly</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/anomalies-stripe" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="wave-square">Anomalies Stripe Chart</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/anomalies-heat-map" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="th">Anomalies Heat Map</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/drought-index" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="water">Drought Index</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/spi-rainfall-graph" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">SPI Rainfall Graph</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/mean-drought-index" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-bar">Mean Drought Index</CDBSidebarMenuItem>
            </NavLink>
            <hr style={{ borderColor: '#fff' }} />
            <NavLink exact to="/eflow" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="tree">Environmental Flow Sites</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/eflow-chart" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">Eflow Chart</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center', backgroundColor: 'white'}}>
          <div className="partner-logos" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            <img src={IWMI} alt="IWMI Logo" className="logo-org iwmi-logo" style={{ height: '40px' }} />
            <span style={{ color: '#000' }}>in partnership with</span>
            <img src={AWARD} alt="AWARD Logo" className="logo-org award-logo" style={{ height: '40px' }} />
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
