import React from 'react';

const LegendComponent = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="legend_rainfall">
        <div className="legend-labels">
          <span>60</span>
          <span>40</span>
          <span>20</span>
          <span>0</span>
          <span>-20</span>
          <span>-40</span>
          <span>-60</span>
        </div>
      </div>
      <span style={{ marginTop: '10px' }}>Anomalies</span>
    </div>
  );
};

export default LegendComponent;
