import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import EFlowChartExport from './EflowChartExport'; // Adjust the import path if necessary

const EFlowChartSimplePage = () => {
  const [searchParams] = useSearchParams();
  const riverCode = searchParams.get('river_code') || "OLIF-B73C-MAMBA"; // Default value
  const startDate = searchParams.get('start_date') || "2023-10-01"; // Default value
  const endDate = searchParams.get('end_date') || "2024-12-31"; // Default value

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading, replace with real data fetching logic
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {loading ? (
        <div className="loading-spinner">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
          <EFlowChartExport
            riverCode={riverCode}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      )}
      {/* Inline style override */}
      <style>
        {`
          .chart-container,
          .chart-container > div,
          .chart-container > div > div,
          .chart-container > div > div > div {
            height: 100% !important;
            width: 100% !important;
            max-height: none !important;
            max-width: none !important;
          }
          .loading-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .spinner-border {
            width: 2rem;
            height: 2rem;
          }
        `}
      </style>
    </div>
  );
};

export default EFlowChartSimplePage;
